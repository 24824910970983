<template>
    <el-dialog title="编辑选项" :visible.sync="show" width="500px">
        <el-table :data="options">
            <el-table-column label="名称" prop="label">
                <template v-slot="{ row }">
                    <el-input v-model="row.label"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="值" prop="value">
                <template v-slot="{ row }">
                    <el-input v-model="row.value"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="类型">
                <template v-slot="{ row }">
                    <el-select v-model="row.type">
                        <el-option label="字符串" value="string"></el-option>
                        <el-option label="数字" value="number"></el-option>
                        <el-option label="布尔" value="boolean"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column>
                <template v-slot="{ $index }">
                    <el-button @click="del($index)" type="text">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-button @click="add" type="text" icon="el-icon-plus">添加</el-button>
        <span slot="footer" class="dialog-footer">
            <el-button @click="show = false">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    name: 'GenOptionsDialog',
    props: {
        visible: {
            required: true,
            type: Boolean
        },
        value: {}
    },
    created() {
        this.show = this.visible;
        this.update();
    },
    data() {
        return {
            show: false,
            options: [
                {
                    label: '',
                    value: '',
                    type: 'string'
                }
            ]
        };
    },
    methods: {
        update() {
            if (this.value) {
                let arr = [];
                JSON.parse(this.value).forEach(item => {
                    let value = item.value.toString(),
                        type = typeof item.value,
                        label = item.label;
                    arr.push({
                        label,
                        value,
                        type
                    });
                });
                this.options = arr;
            }
        },
        confirm() {
            let arr = [];
            for (let i = 0; i < this.options.length; i++) {
                if (!this.options[i].label || !this.options[i].value) {
                    return;
                }
                let label = this.options[i].label;
                let value;
                switch (this.options[i].type) {
                    case 'string':
                        value = this.options[i].value.toString();
                        break;
                    case 'number':
                        value = Number(this.options[i].value);
                        break;
                    case 'boolean':
                        value = 'false' !== this.options[i].value;
                        break;
                }
                arr.push({
                    label: label,
                    value: value
                });
            }
            this.$emit('input', JSON.stringify(arr));
            this.$emit('update:visible', false);
        },
        add() {
            this.options.push({
                label: '',
                value: '',
                type: 'string'
            });
        },
        del(index) {
            this.options.splice(index, 1);
        }
    },
    watch: {
        visible(val) {
            this.show = val;
        },
        show(val) {
            this.$emit('update:visible', val);
        },
        value() {
            this.update();
        }
    }
};
</script>

<style scoped></style>
