var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"编辑选项","visible":_vm.show,"width":"500px"},on:{"update:visible":function($event){_vm.show=$event}}},[_c('el-table',{attrs:{"data":_vm.options}},[_c('el-table-column',{attrs:{"label":"名称","prop":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{model:{value:(row.label),callback:function ($$v) {_vm.$set(row, "label", $$v)},expression:"row.label"}})]}}])}),_c('el-table-column',{attrs:{"label":"值","prop":"value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}})]}}])}),_c('el-table-column',{attrs:{"label":"类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{model:{value:(row.type),callback:function ($$v) {_vm.$set(row, "type", $$v)},expression:"row.type"}},[_c('el-option',{attrs:{"label":"字符串","value":"string"}}),_c('el-option',{attrs:{"label":"数字","value":"number"}}),_c('el-option',{attrs:{"label":"布尔","value":"boolean"}})],1)]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.del($index)}}},[_vm._v("删除")])]}}])})],1),_c('el-button',{attrs:{"type":"text","icon":"el-icon-plus"},on:{"click":_vm.add}},[_vm._v("添加")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.show = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirm}},[_vm._v("确 定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }